<template>
    <NuxtLink
        :to="localePath(`/${cardItem.uri}`)"
        class="base-card"
    >
        <div class="base-card__inner">
            <div class="base-card__header">
                <BaseDate
                    v-if="isEvent && cardItem?.startDate"
                    :start-date="cardItem?.startDate"
                    :end-date="cardItem?.endDate"
                    class="base-card__event-date"
                />
                <h3>{{ cardItem.title }}</h3>
            </div>
            <div class="base-card__tags">
                <slot name="tags">
                    <BaseTag
                        v-for="postType in cardItem?.postTypes"
                        :key="postType.title"
                        :text="postType.title"
                        data-fill
                    />
                </slot>
            </div>
            <div v-if="cardItem?.video || cardItem?.image?.length" class="base-card__media">
                <ClientOnly v-if="cardItem?.video">
                    <LazyBaseVideo
                        :video="cardItem?.video"
                        :poster="cardItem?.image[0]?.w380"
                        class="base-card__video"
                    />
                </ClientOnly>
                <ImageAsset
                    v-else-if="cardItem?.image?.length"
                    :image="cardItem?.image[0]"
                    loading="lazy"
                    alt=""
                    class="base-card__image"
                    sizes="(min-width: 768px) 303px, 100vw"
                />
            </div>
            <BaseDate v-if="isNews && cardItem?.postDate && cardItem?.showDate" :start-date="cardItem.postDate" class="base-card__news-date" />
        </div>
    </NuxtLink>
</template>

<script>
const CARD_TYPES = ['news', 'event', 'search'];

export default {
    props: {
        cardItem: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        type: {
            type: String,
            default: 'news',
            validate: (value) => {
                return CARD_TYPES.includes(value);
            }
        }
    },
    computed: {
        isNews() {
            return this.type === CARD_TYPES[0];
        },
        isEvent() {
            return this.type === CARD_TYPES[1];
        },
        isSearch() {
            return this.type === CARD_TYPES[2];
        }
    }
};
</script>

<style src="./BaseCard.less" lang="less"></style>
